import { Card, Row, Col, Button, Container } from 'react-bootstrap';
import { useState } from 'react';

function NewCard() {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleAssignButtonClick = () => {
        // Atama butonuna tıklama işlemleri burada yapılabilir
        console.log("Input value:", inputValue);
    };

    return (
      <Container  style={{ marginTop: '100px' }}>
        <Card style={{ border: '1px solid #000 !important', borderRadius: '15px', margin: '20px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
            <Card.Body>
                <Row className="align-items-center">
                    <Col xs={12} sm={6}>
                        <div style={{ textAlign: "left", padding: '20px' }}>
                            <h3 style={{ color: "white", fontSize: '24px' }}>To stay informed about updates, join our newsletter!</h3>
                            <p style={{ color: "white", fontSize: '14px', fontWeight: 300 }}>Sign up for our newsletter now to take advantage of the latest products and surprise opportunities, and receive up-to-date emails from industry-leading cybersecurity experts.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>
                        <div style={{ padding: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="E-Mail Adress"
                                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', border: 'none', height: "50px", flex: '1', marginRight: '10px' }}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                />
                                <Button onClick={handleAssignButtonClick}>Register a News</Button>
                            </div>
                            <p style={{ fontSize: '10px', color: 'white', marginTop: '10px' }}>By clicking on "Register" you confirm that you have read and accepted our Terms and Conditions.</p>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </Container>
    );
}

export default NewCard;
