import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Dropdown } from 'react-bootstrap';
import styles from './Header.module.css';
import logo from '../assest/img/NameDefence.png'
import engImg from '../assest/img/englishFlag.svg'
import trImg from '../assest/img/turkeyFlag.png'

export default function Header() {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${styles.navbar} ${isSticky ? styles.sticky : ''}`} style={{ backgroundColor: '#0A0E17' }}>
      <Container className='py-2'>
        <Navbar expand="lg" style={{ backgroundColor: 'transparent' }}>
          <Container className="d-flex justify-content-between align-items-center">
            <Navbar.Brand href="#home" className="me-0">
              <img src={logo} alt="Logo" style={{ height: '30px', filter: 'brightness(0) invert(1)' }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ fontSize: '1.5rem', backgroundColor: '#3D5AFE' }} />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className={`${styles.right} ms-auto d-flex align-items-center flex-row`}>
                <Button href='/auth/' variant='outline-primary' className="me-2" style={{borderColor: '#3D5AFE', color: '#3D5AFE', backgroundColor: 'transparent', height: '38px', fontSize: '1.2rem'}}>Try Now</Button>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center" style={{borderColor: '#9E9E9E', color: '#9E9E9E', backgroundColor: 'transparent', height: '38px', fontSize: '1.2rem'}}>
                    <img src={engImg} alt="Flag" style={{ width: '20px', marginRight: '5px' }}/> EN
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: '#FFFFFF', borderColor: '#1E2738' }}>
                    <Dropdown.Item href="#/action-1" className="d-flex align-items-center" style={{color: '#0A0E17', fontSize: '1.2rem'}} aria-live="polite">
                      <img src={trImg} alt="Flag" style={{ width: '20px', marginRight: '5px' }}/> TR
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </div>
  );
}