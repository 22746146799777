import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Earth from './Earth';
import img from '../assest/img/rowImg.svg';
import img2 from '../assest/img/rowImg2.svg';
import img3 from '../assest/img/rowImg3.svg';
import img4 from '../assest/img/rowImg4.svg';
import img5 from '../assest/img/rowImg5.svg';
import Slider from "react-slick";

export default function Home() {
  const [inputValue, setInputValue] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const slides = [
    { image: img, alt: "First slide" },
    { image: img2, alt: "Second slide" },
    { image: img3, alt: "Third slide" },
    { image: img4, alt: "Fourth slide" },
    { image: img5, alt: "Fifth slide" }
  ];

  const getLeftPadding = () => {
    if (windowWidth >= 1200) return '20%';
    if (windowWidth >= 992) return '10%';
    return '0';
  };

  return (
    <Container fluid id='home' className="px-0" style={{ paddingLeft: getLeftPadding() }}>
      <Row className='align-items-center'>
        <Col xs={12} lg={6} className="d-flex justify-content-center flex-column p-4 p-md-5" style={{ zIndex: 2 }}>
          <h1 className="responsive-heading" style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Dynamic Name<br />
            Server and Firewall
          </h1>
          <p className="responsive-text" style={{ color: "gray", textAlign: 'left' }}>Expert Solutions for Current Cyber Threats</p>
          <br />
          <Row className="justify-content-start">
            <Col xs={12} lg={10} className="d-flex flex-column flex-md-row">
              <input
                type="text"
                className="form-control mb-2 mb-md-0"
                placeholder="E-Mail Address"
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: '1px solid', flex: 1 }}
                value={inputValue}
                onChange={handleInputChange}
              />
              <Button href='/auth' variant='outline-light' className='ms-md-2 mt-2 mt-md-0'>Request a Demo</Button>
            </Col>
          </Row>
          <p className='subtitle mt-2' style={{ fontSize: '10px', color: 'light gray', textAlign: 'left' }}>You are considered to have accepted data sharing.</p>
        </Col>

        <Col xs={12} lg={6} style={{ position: 'relative', height: '80vh', minHeight: '300px', padding: 0 }}>
          <div style={{ 
            position: 'absolute', 
            top: '50%', 
            left: windowWidth >= 992 ? '40%' : '50%', 
            width: '100%', 
            height: '100%', 
            transform: 'translate(-50%, -50%)',
          }}>
            <Earth />
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index}>
                <img src={slide.image} alt={slide.alt} className="img-fluid" style={{ filter: "brightness(1000%)", maxHeight: '100px', width: 'auto', margin: '0 auto' }}/>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>
    </Container>
  )
}