import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import imgName from '../assest/img/chain.svg';

const SecurityBox = () => {
  return (
    <Container style={{ marginTop:"200px" }}>
      <Row className='mt-5'>
        <Col xs={12} md={6}>
          {/* Sol taraftaki resim */}
          <img src={imgName} alt="Side Image" style={{ width: '100%', height: 'auto' }} />
        </Col>
        <Col xs={12} md={6}>
          {/* Sağ taraftaki başlık ve metin */}
          <div style={{ paddingLeft: '30px' }}> <p style={{ color: "#ADD8E6", fontSize: '24px' }}>Central Security</p> <h2 style={{ color: "white", fontSize: '48px' }}>Much More!</h2> <p style={{ color: "white", fontSize: '18px', fontWeight: 'lighter' }}>You can manage DNS routing and security policies separately through dedicated screens. It applies routing rules first and then security policies. By integrating with Guardpot and Allarmia, it proactively blocks malicious IP addresses and strengthens the system with automated interventions. <br/><br/> NameDefence is easy to install and integrate, quickly becoming operational by installing the server application on a device. Additionally, it provides regular reports by analyzing user behaviors, offering administrators an overview and the necessary information for strategic decision-making.</p> </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SecurityBox;
