import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import img from '../assest/img/1.png';
import img1 from '../assest/img/1-1.png';
import img2 from '../assest/img/2.png';
import img3 from '../assest/img/3.png';
import img4 from '../assest/img/4.png';
import img5 from '../assest/img/4-1.png';
import img6 from '../assest/img/4-2.png';
import img7 from '../assest/img/5.png';
import { Button } from 'react-bootstrap';

export default function Features() {
  return (
    <Container id='features'>
        <Row>
            <Col className='d-flex justify-content-center'>
                <div style={{ marginTop: "70px", textAlign: "center" }}>
                    <p style={{ color: "#ADD8E6", fontSize: '24px' }}>Features</p>
                    <h3 style={{ color: "white", fontSize: '36px' }}>Our comprehensive DNS service</h3>
                    <h3 style={{ color: "white", fontSize: '36px' }}>It includes the following:</h3>
                </div>
            </Col>
        </Row>
        <Row>
            <Col className='d-flex justify-content-center'>
                <div style={{ textAlign: "center" }}>
                    <p className='subtitle'>We are passionate about DNS security and have a proven track record in delivering top-notch services to businesses of all sizes.</p>
                </div>
            </Col>
        </Row>
        <Row className='mt-5'>
            {[
                { img: img, title: "DNS Server", desc: "Has its own unique DNS server, which has been developed entirely from scratch and designed to take the fastest possible action in terms of security mechanisms." },
                { img: img1, title: "DNS Routing", desc: "You can configure DNS routing using rule-based approaches, similar to writing rules, and utilize wildcards in your routing configurations." },
                { img: img2, title: "DNS Firewall", desc: "You can block access to categorized domain names using the rules you create with NameDefence, or you can redirect them to desired pages." },
                { img: img3, title: "Central Management", desc: "Quickly be deployed across your entire DNS infrastructure through centralized policies, eliminating the need for individual server configurations." },
                { img: img4, title: "AI-Supported Categorization", desc: "With AI and Guardpot agents, an undetectable DNS categorization is provided, which includes bot-protected sites and even evasive ones." },
                { img: img6, title: "Dynamic Cache Policy", desc: "You can manipulate DNS TTL cache parameters as you wish, thus preventing unnecessary traffic from occurring." },
                { img: img7, title: "Monitoring Screens", desc: "You can track user DNS requests with timestamps and detect their habits in categorical and URL-based live screens." },
                { img: img5, title: "Wildcard Policies", desc: "The use of wildcards provides an effective solution by handling letter or number additions in domain names with a single rule." }
            ].map((item, index) => (
                <Col xs={12} md={6} lg={3} key={index} className="mb-4">
                    <Card className="h-100 transparent-card position-relative">
                        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                            <div className='mt-5 mb-3 text-center'>
                                <img src={item.img} alt={item.title} className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }} /><br/><br/>
                                <h4 className="position-relative" style={{ zIndex: 2, color:"white" }}>{item.title}</h4>
                            </div>
                            <p className='subtitle text-center'>{item.desc}</p>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
        <Row className="justify-content-center mt-4">
            <Col xs={12} className="text-center">
                <Button href='/auth' variant='outline-light' style={{ width:"250px", height:"40px" }}>Request a Demo</Button>
            </Col>
        </Row>
    </Container>
  )
}
