
import Home from "./components/Home";
import Header from "./components/Header";
import Features from "./components/Features";
import SecurityBox from "./components/securityBox";
import NewServices from "./components/newServices";
import NewCard from "./components/newCard";
import Footer from "./components/Footer";




function App() {
  return (
    <div className="App">
            <div className="header"><Header/></div>
            <div className="home"><Home/></div>
            <div className="home"><Features/></div>
            <div className="home"><SecurityBox/></div>
      
            <div className="home"><NewCard/></div>
            <div className="home"><Footer/></div>


     
    </div>
  );
}

export default App;
